import type { AppProps } from 'next/app';
import Router from 'next/router';
import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import 'lazysizes';

import '@/styles/main.scss';
import gilroy from '@/fonts/font-gilroy';
import { Fancybox } from '@fancyapps/ui';
import useScrollToTop from '@/hooks/useScrollToTop';
import { AuthProvider } from 'react-oidc-context';
import { NodeEnv } from '@wap-client/constants';
import { Cookie } from '@/utils/cookie';
import { handleGetUserFavorites } from '@/utils/functions';

function MyApp({ Component, pageProps }: AppProps) {
  useScrollToTop();

  useEffect(() => {
    const handleRouteStart = () => {
      NProgress.start();
    };

    const handleRouteComplete = () => {
      NProgress.done();
    };

    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteComplete);

    return function cleanup() {
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteComplete);
    };
  }, []);

  useEffect(() => {
    if (
      pageProps &&
      pageProps?.page &&
      pageProps?.page?.path &&
      pageProps?.page?.id
    ) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageView',
        pagePath: pageProps.page.path,
        pageId: pageProps.page.id,
        userId: 'f0442c73-e925-4229-ac13-02e292d3fad7',
      });
    }
  }, [pageProps]);

  useEffect(() => {
    if (Cookie.get('token')) {
      handleGetUserFavorites();
    }
  }, []);

  useEffect(() => {
    // Customize Fancybox
    Fancybox.bind('[data-fancybox]');
    // @ts-ignore
    Fancybox.bind('[data-fancybox="single"]', {
      groupAttr: false,
    });
  }, []);

  const httpUrl =
    process.env.NEXT_PUBLIC_ENV === NodeEnv.Production ? `https://` : `http://`;

  const oidcConfig = {
    authority: process.env.NEXT_PUBLIC_IDENTITY_SERVER_AUTHORITY || '',
    client_id: process.env.NEXT_PUBLIC_IDENTITY_SERVER_CLIENT_ID || '',
    redirect_uri: `${httpUrl}${pageProps?.headers?.host}/auth/login`,
    scope: 'wapui.manage',
  };

  return (
    <>
      <AuthProvider {...oidcConfig}>
        <main className={`${gilroy.variable}`}>
          <Component {...pageProps} />
        </main>
      </AuthProvider>
    </>
  );
}

export default MyApp;
